<template>

    <div class="column is-6">
        <h1 class="title is-3">{{ $t('Install root certificates in Windows') }}</h1>
        <p><span v-html="$t('CA root certificates can be installed by a')"></span>:</p>
        <div style="height:0.75rem;"></div>
        <p><span v-html="$t('Download 1')"></span>.</p>
<!--        <p v-html="$t('Start the file CA_RSA1.cer')"></p>-->
        <p>2. {{ $t('View') }} <b>CA_RSA1.cer</b> {{ $t('Certificate Contents') }}.</p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/windows/` + lang + `/installing_in_os1.png`)">
        <div style="height:0.75rem;"></div>
        <p>3. {{ $t('Click “Install certificate” in the window “Certificate”') }}.</p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/windows/` + lang + `/installing_in_os2.png`)">
        <div style="height:0.75rem;"></div>
        <p>4. {{ $t('The CA Root Certificate is imported to your computer if settings set by the Wizard as default are selected') }}.</p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/windows/` + lang + `/installing_in_os3.png`)">
        <div style="height:0.75rem;"></div>
        <p>5. {{ $t('Click “Ready” to complete the Wizard work') }}.</p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/windows/` + lang + `/installing_in_os4.png`)">
        <div style="height:0.75rem;"></div>
        <p>6. {{ $t('Click “OK” on the Wizard warning message') }}.</p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/windows/` + lang + `/installing_in_os5.png`)">
        <div style="height:0.75rem;"></div>
        <p v-html="$t('Make sure that the certificate is installed in the Windows storage')"></p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/windows/` + lang + `/installing_in_os6.png`)">
        <div style="height:0.75rem;"></div>
        <p v-html="$t('Install the certificate CA_RSA2.cer in the Windows storage')"></p>
        <div style="height:0.75rem;"></div>
        <p>{{ $t('Installation of CA Root Certificates is completed') }}.</p>
    </div>

</template>

<script>
export default {
  name: 'IIW',
  computed: {
    lang () {
      return this.$i18n.locale
    }
  }
}
</script>
