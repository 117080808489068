<template>

  <div class="column is-6">
    <h1 class="title is-3">{{ $t('Initialization of eToken PRO 72k (Java)') }}</h1>
    <p class="is-red">{{ $t('Important! If a security token contains valid key data used in other information systems') }}.</p>
    <div style="height:0.75rem;"></div>
    <p class="is-red">{{ $t('During initialization, all data on security token will be deleted') }}.</p>
    <div style="height:0.75rem;"></div>
    <p>1. {{ $t('Connect security token to USB port of the PC') }}.</p>
    <div style="height:0.75rem;"></div>
    <img v-bind:src="img1">
    <div style="height:0.75rem;"></div>
    <p>2. {{ $t('If the window shown on the picture pops up, press “Cancel”') }}.</p>
    <div style="height:0.75rem;"></div>
    <p>3. {{ $t('Launch PKI Client in the Windows system tray') }} <img src="@/assets/img/etoken/etokenpro72k2.jpg"> {{ $t('(located below on the right)') }}.</p>
    <div style="height:0.75rem;"></div>
    <p>{{ $t('This should open the main window of PKI Client') }}:</p>
    <div style="height:0.75rem;"></div>
    <img v-bind:src="img3">
    <div style="height:0.75rem;"></div>
    <p>4. {{ $t('Press button') }} <img src="@/assets/img/etoken/etokenpro72k11.jpg"> .</p>
    <div style="height:0.75rem;"></div>
    <p>{{ $t('This should open the following window') }}:</p>
    <div style="height:0.75rem;"></div>
    <<img v-bind:src="img4">
    <div style="height:0.75rem;"></div>
    <p>5. {{ $t('Go to “eToken” tab') }}.</p>
    <div style="height:0.75rem;"></div>
    <img v-bind:src="img5">
    <div style="height:0.75rem;"></div>
    <p>6. {{ $t('Press button') }} <img src="@/assets/img/etoken/etokenpro72k12.jpg"> {{ $t('to initialize eToken') }}.</p>
    <div style="height:0.75rem;"></div>
    <p>7. {{ $t('In the opened window disable the option “Change the password on first login”') }}.</p>
    <div style="height:0.75rem;"></div>
    <img v-bind:src="img6">
    <div style="height:0.75rem;"></div>
    <p>8. {{ $t('Press the “Start” button') }}.</p>
    <div style="height:0.75rem;"></div>
    <p>{{ $t('If you see an error shown on the picture below') }}.</p>
    <div style="height:0.75rem;"></div>
    <p class="is-red">{{ $t('Important! Remember this password, as it will be used later during sending a request for digital signature certificate') }}.</p>
    <div style="height:0.75rem;"></div>
    <img v-bind:src="img7">
    <div style="height:0.75rem;"></div>
    <p>9. {{ $t('Press “Start” button') }}.</p>
    <div style="height:0.75rem;"></div>
    <p>10. {{ $t('In the opened window press “OK”') }}.</p>
    <img v-bind:src="img8">
    <div style="height:0.75rem;"></div>
    <p>11. {{ $t('Wait a while and press “ОК”') }}.</p>
    <div style="height:0.75rem;"></div>
    <img v-bind:src="img9">
    <img v-bind:src="img10">
    <div style="height:0.75rem;"></div>
    <p>{{ $t('Security token is ready for sending a request for digital signature certificate') }}!</p>
  </div>

</template>

<script>
export default {
  name: 'SupportJaCarta',
  computed: {
    lang () {
      return this.$i18n.locale
    },
    img1 () {
      const en = require('@/assets/img/etoken/etokenpro72k1_en.jpg')
      const ru = require('@/assets/img/etoken/etokenpro72k1.jpg')
      return this.lang === 'ru' ? ru : en
    },
    img3 () {
      const en = require('@/assets/img/etoken/etokenpro72k3_en.jpg')
      const ru = require('@/assets/img/etoken/etokenpro72k3.jpg')
      return this.lang === 'ru' ? ru : en
    },
    img4 () {
      const en = require('@/assets/img/etoken/etokenpro72k4_en.jpg')
      const ru = require('@/assets/img/etoken/etokenpro72k4.jpg')
      return this.lang === 'ru' ? ru : en
    },
    img5 () {
      const en = require('@/assets/img/etoken/etokenpro72k5_en.jpg')
      const ru = require('@/assets/img/etoken/etokenpro72k5.jpg')
      return this.lang === 'ru' ? ru : en
    },
    img6 () {
      const en = require('@/assets/img/etoken/etokenpro72k6_en.jpg')
      const ru = require('@/assets/img/etoken/etokenpro72k6.jpg')
      return this.lang === 'ru' ? ru : en
    },
    img7 () {
      const en = require('@/assets/img/etoken/etokenpro72k7_en.jpg')
      const ru = require('@/assets/img/etoken/etokenpro72k7.jpg')
      return this.lang === 'ru' ? ru : en
    },
    img8 () {
      const en = require('@/assets/img/etoken/etokenpro72k8_en.jpg')
      const ru = require('@/assets/img/etoken/etokenpro72k8.jpg')
      return this.lang === 'ru' ? ru : en
    },
    img9 () {
      const en = require('@/assets/img/etoken/etokenpro72k9_en.jpg')
      const ru = require('@/assets/img/etoken/etokenpro72k9.jpg')
      return this.lang === 'ru' ? ru : en
    },
    img10 () {
      const en = require('@/assets/img/etoken/etokenpro72k10_en.jpg')
      const ru = require('@/assets/img/etoken/etokenpro72k10.jpg')
      return this.lang === 'ru' ? ru : en
    }
  }
}
</script>
