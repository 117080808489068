<template>

  <div class="column is-6">
    <h1 class="title is-3">{{ $t('Install root certificates') }}</h1>
    <p><span v-html="$t('CA root certificates can be installed by a')"></span>:</p>
    <div style="height:1rem;"></div>
    <div class="tabs">
      <ul>
        <li :class="[ tab === 'explorer' ? 'is-active' : '']"><a @click="tab = 'explorer'">Internet Explorer</a></li>
        <li :class="[ tab === 'mozilla' ? 'is-active' : '']"><a @click="tab = 'mozilla'">Mozilla Firefox</a></li>
        <li :class="[ tab === 'chrome' ? 'is-active' : '']"><a @click="tab = 'chrome'">Google Chrome</a></li>
        <li :class="[ tab === 'opera' ? 'is-active' : '']"><a @click="tab = 'opera'">Opera</a></li>
      </ul>
    </div>

    <transition name="slide-fade">
      <div v-if="tab ==='explorer'">
        <p><span v-html="$t('Download 1')"></span>.</p>
        <p>{{ $t('Click on Internet Explorer shortcut to start it. Select “Tools -> Internet Options” in the IE main menu') }}.</p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/ie/` + lang + `/installing_in_ie1.png`)">
        <div style="height:0.75rem;"></div>
        <p>{{ $t('Go to the tab “Content” in the window “Internet Options” and click “Certificates”') }}.</p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/ie/` + lang + `/installing_in_ie2.png`)">
        <div style="height:0.75rem;"></div>
        <p v-html="$t('Go to the tab “Trusted Root Certificate Authorities”')"></p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/ie/` + lang + `/installing_in_ie3.png`)">
        <div style="height:0.75rem;"></div>
        <p v-html="$t('If certificates with the name Gamma Technologies Certificate Authority are absent in the list, it is necessary to install it')"></p>
        <p>{{ $t('Perform the following operations to install the certificates in IE browser') }}:</p>
        <p>{{ $t('Click “Import…” on the tab “Trusted Root Certificate Authorities”') }}.</p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/ie/` + lang + `/installing_in_ie4.png`)">
        <div style="height:0.75rem;"></div>
        <p>{{ $t('Follow the Wizard instructions – click “Next >”') }}.</p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/ie/` + lang + `/installing_in_ie5.png`)">
        <div style="height:0.75rem;"></div>
        <p>{{ $t('Click “Browse…” to select the certificate file') }}.</p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/ie/` + lang + `/installing_in_ie6.png`)">
        <div style="height:0.75rem;"></div>
        <p v-html="$t('Find the file of CA Root Certificate - CA_RSA1.cer')"></p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/ie/` + lang + `/installing_in_ie7.png`)">
        <div style="height:0.75rem;"></div>
        <p>{{ $t('The path to CA Certificate will be displayed') }}.</p>
        <div style="height:0.75rem;"></div>
        <p>{{ $t('The following Wizard window prompts to select the storage for CA Certificate') }}.</p>
        <p>{{ $t('If import is initialized from another section of certificates storage') }}.</p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/ie/` + lang + `/installing_in_ie8.png`)">
        <div style="height:0.75rem;"></div>
        <p>{{ $t('Click “Next >” to continue the installation in the Wizard window') }}.</p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/ie/` + lang + `/installing_in_ie9.png`)">
        <div style="height:0.75rem;"></div>
        <p>{{ $t('Click “Finish” to complete Wizard work') }}.</p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/ie/` + lang + `/installing_in_ie10.png`)">
        <div style="height:0.75rem;"></div>
        <p>{{ $t('Click “Yes” in the window “Security Warning“') }}.</p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/ie/` + lang + `/installing_in_ie11.png`)">
        <div style="height:0.75rem;"></div>
        <p>{{ $t('Click “OK” in the system message') }}.</p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/ie/` + lang + `/installing_in_ie12.png`)">
        <div style="height:0.75rem;"></div>
        <p>{{ $t('Open the tab “Trusted Root Certificate Authorities”') }}.</p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/ie/` + lang + `/installing_in_ie13.png`)">
        <div style="height:0.75rem;"></div>
        <p>{{ $t('Click “View” to open the certificate information window') }}.</p>
        <div style="height:0.75rem;"></div>
        <p>{{ $t('Close the certificate') }}.</p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/ie/` + lang + `/installing_in_ie14.png`)">
        <div style="height:0.75rem;"></div>
        <p v-html="$t('Go to tab “Intermediate Certificate Authorities”')"></p>
        <p v-html="$t('If certificate with the name Gamma Technologies Primary CA')"></p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/ie/` + lang + `/installing_in_ie15.png`)">
        <div style="height:0.75rem;"></div>
        <p>{{ $t('Perform the following operations to install the certificate in IE browser') }}.</p>
        <p>{{ $t('Click “Import…” on the tab “Intermediate Certificate Authorities”') }}:</p>
        <p>{{ $t('Follow the Wizard instructions – click “Next >”') }}.</p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/ie/` + lang + `/installing_in_ie16.png`)">
        <div style="height:0.75rem;"></div>
        <p>{{ $t('Click “Browse…” to select the certificate file') }}.</p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/ie/` + lang + `/installing_in_ie17.png`)">
        <div style="height:0.75rem;"></div>
        <p v-html="$t('Find the file of CA Root Certificate - CA_RSA2.cer')"></p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/ie/` + lang + `/installing_in_ie18.png`)">
        <div style="height:0.75rem;"></div>
        <p>{{ $t('The path to CA Certificate will be displayed') }}.</p>
        <div style="height:0.75rem;"></div>
        <p>{{ $t('The following Wizard window prompts to select the storage for CA Certificate') }}.</p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/ie/` + lang + `/installing_in_ie19.png`)">
        <div style="height:0.75rem;"></div>
        <p>{{ $t('If import is initialized from another section of certificates storage') }}. {{ $t('Click “Next >” to continue the installation in the Wizard window') }}.</p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/ie/` + lang + `/installing_in_ie20.png`)">
        <div style="height:0.75rem;"></div>
        <p>{{ $t('Click “Finish” to complete Wizard work') }}.</p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/ie/` + lang + `/installing_in_ie21.png`)">
        <div style="height:0.75rem;"></div>
        <p>{{ $t('Click “OK” in the system message') }}.</p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/ie/` + lang + `/installing_in_ie22.png`)">
        <div style="height:0.75rem;"></div>
        <p>{{ $t('Open the tab “Trusted Root Certificate Authorities”') }}.</p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/ie/` + lang + `/installing_in_ie23.png`)">
        <div style="height:0.75rem;"></div>
        <p>{{ $t('Click “View” to open the certificate information window') }}.</p>
        <div style="height:0.75rem;"></div>
        <p>{{ $t('Close the certificate viewing window') }}.</p>
        <div style="height:0.75rem;"></div>
        <p>{{ $t('Check for installation of CA Root Certificates is completed') }}.</p>
      </div>
    </transition>

    <transition name="slide-fade">
      <div v-if="tab ==='mozilla'">
        <p><span v-html="$t('Download 1')"></span>.</p>
        <p>{{ $t('Click on Mozilla Firefox shortcut to start it. Select “Options” in the main menu “Tools”') }}.</p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/firefox/` + lang + `/installing_in_firefox1.png`)">
        <div style="height:0.75rem;"></div>
        <p>{{ $t('Go to the tab “Authorities” in the window “Certificate Manager” and click “Import…”') }}.</p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/firefox/` + lang + `/installing_in_firefox2.png`)">
        <div style="height:0.75rem;"></div>
        <p v-html="$t('Find the file of CA Root Certificate - CA_RSA1.cer')"></p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/firefox/` + lang + `/installing_in_firefox3.png`)">
        <div style="height:0.75rem;"></div>
        <p>{{ $t('In the window “Downloading Certificate” select the purposes for which the certificate import is performed. It is recommended to select all offered variants by checking the boxes and then click “OK”') }}.</p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/firefox/` + lang + `/installing_in_firefox4.png`)">
        <div style="height:0.75rem;"></div>
        <p>{{ $t('Go to the tab “Authorities” in the window “Certificate Managemer” and find the installed CA Root Certificate in the list to check the correctness of performed operations') }}.</p>
        <p>{{ $t('Select the certificate and click “View”') }}.</p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/firefox/` + lang + `/installing_in_firefox5.png`)">
        <div style="height:0.75rem;"></div>
        <p>{{ $t('Make sure that the certificate is valid') }}.</p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/firefox/` + lang + `/installing_in_firefox6.png`)">
        <div style="height:0.75rem;"></div>
        <p v-html="$t('Install the certificate CA_RSA2.cer. The procedure for certificate installation is similar to operations performed within the installation of certificate CA_RSA1.cer')"></p>
      </div>
    </transition>

    <transition name="slide-fade">
      <div v-if="tab ==='chrome'">
        <p><span v-html="$t('Download 1')"></span>.</p>
        <p>{{ $t('Click on Google Chrome shortcut to start it. Go to “Settings”, click on active link “Show Advanced Settings”') }}.</p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/chrome/` + lang + `/installing_in_chrome1.png`)">
        <div style="height:0.75rem;"></div>
        <p v-html="$t('Go to the tab “Trusted Root Certificate Authorities”')"></p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/chrome/` + lang + `/installing_in_chrome2.png`)">
        <div style="height:0.75rem;"></div>
        <p v-html="$t('If certificates with the name Gamma Technologies Certificate Authority are absent in the list, it is necessary to install it')"></p>
        <p>{{ $t('Perform the following operations to install the certificate in Google Chrome browser') }}:</p>
        <p>{{ $t('Click “Import…” on the tab “Trusted Root Certificate Authorities”') }}.</p>
        <p>{{ $t('Follow the Wizard instructions – click “Next >”') }}.</p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/chrome/` + lang + `/installing_in_chrome3.png`)">
        <div style="height:0.75rem;"></div>
        <p>{{ $t('Click “Browse…” to select the certificate file') }}.</p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/chrome/` + lang + `/installing_in_chrome4.png`)">
        <div style="height:0.75rem;"></div>
        <p v-html="$t('Find the file of CA Root Certificate - CA_RSA1.cer')"></p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/chrome/` + lang + `/installing_in_chrome5.png`)">
        <div style="height:0.75rem;"></div>
        <p>{{ $t('The path to CA Certificate will be displayed') }}.</p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/chrome/` + lang + `/installing_in_chrome6.png`)">
        <div style="height:0.75rem;"></div>
        <p>{{ $t('The following Wizard window prompts to select the storage for CA Certificate') }}.</p>
        <p>{{ $t('If import is initialized from another section of certificates storage') }}.</p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/chrome/` + lang + `/installing_in_chrome7.png`)">
        <div style="height:0.75rem;"></div>
        <p>{{ $t('Click “Finish” to complete Wizard work') }}.</p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/chrome/` + lang + `/installing_in_chrome8.png`)">
        <div style="height:0.75rem;"></div>
        <p>{{ $t('Click “Yes” in the window “Security Warning“') }}.</p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/chrome/` + lang + `/installing_in_chrome9.png`)">
        <div style="height:0.75rem;"></div>
        <p>{{ $t('Click “OK” in the system message') }}.</p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/chrome/` + lang + `/installing_in_chrome10.png`)">
        <div style="height:0.75rem;"></div>
        <p>{{ $t('Open the tab “Trusted Root Certificate Authorities”') }}.</p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/chrome/` + lang + `/installing_in_chrome11.png`)">
        <div style="height:0.75rem;"></div>
        <p>{{ $t('Click “View” to open the certificate information window') }}.</p>
        <p>{{ $t('Close the certificate') }}.</p>
        <p v-html="$t('Go to tab “Intermediate Certificate Authorities”')"></p>
        <p v-html="$t('If certificate with the name Gamma Technologies Primary CA')"></p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/chrome/` + lang + `/installing_in_chrome13.png`)">
        <div style="height:0.75rem;"></div>
        <p>{{ $t('Perform the following operations to install the certificate in Google Chrome browser') }}.</p>
        <p>{{ $t('Click “Import…” on the tab “Intermediate Certificate Authorities”') }}.</p>
        <p>{{ $t('Follow the Wizard instructions – click “Next >”') }}.</p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/chrome/` + lang + `/installing_in_chrome14.png`)">
        <div style="height:0.75rem;"></div>
        <p>{{ $t('Click “Browse…” to select the certificate file') }}.</p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/chrome/` + lang + `/installing_in_chrome15.png`)">
        <div style="height:0.75rem;"></div>
        <p v-html="$t('Find the file of CA Root Certificate - CA_RSA2.cer')"></p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/chrome/` + lang + `/installing_in_chrome16.png`)">
        <div style="height:0.75rem;"></div>
        <p>{{ $t('The path to CA Certificate will be displayed') }}.</p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/chrome/` + lang + `/installing_in_chrome17.png`)">
        <div style="height:0.75rem;"></div>
        <p>{{ $t('The following Wizard window prompts to select the storage for CA Certificate') }}.</p>
        <p>{{ $t('If import is initialized from another section of certificates storage') }}. {{ $t('Click “Next >” to continue the installation in the Wizard window') }}.</p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/chrome/` + lang + `/installing_in_chrome18.png`)">
        <div style="height:0.75rem;"></div>
        <p>{{ $t('Click “Finish” to complete Wizard work') }}.</p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/chrome/` + lang + `/installing_in_chrome19.png`)">
        <div style="height:0.75rem;"></div>
        <p>{{ $t('Click “OK” in the system message') }}.</p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/chrome/` + lang + `/installing_in_chrome20.png`)">
        <div style="height:0.75rem;"></div>
        <p>{{ $t('Open the tab “Intermediate Certificate Authorities”') }}.</p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/chrome/` + lang + `/installing_in_chrome21.png`)">
        <div style="height:0.75rem;"></div>
        <p>{{ $t('Click “View” to open the certificate information window') }}.</p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/chrome/` + lang + `/installing_in_chrome22.png`)">
        <div style="height:0.75rem;"></div>
        <div style="height:0.75rem;"></div>
        <p>{{ $t('Close the certificate viewing window') }}.</p>
        <div style="height:0.75rem;"></div>
        <p>{{ $t('Check for installation of CA Root Certificates is completed') }}.</p>
      </div>
    </transition>

    <transition name="slide-fade">
      <div v-if="tab ==='opera'">
        <p><span v-html="$t('Download 1')"></span>.</p>
        <p>{{ $t('Click on Opera shortcut to start it. Select the menu item “Settings -> Preferences”') }}.</p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/opera/` + lang + `/installing_in_opera1.png`)">
        <div style="height:0.75rem;"></div>
        <p>{{ $t('In the right corner of the expansion window go to “Hot keys for extensions”') }}.</p>
        <p>{{ $t('Select “Security” in the side panel and click “Manage HTTPS/SSL certificates and settings”') }}.</p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/opera/` + lang + `/installing_in_opera2.png`)">
        <div style="height:0.75rem;"></div>
        <p>{{ $t('Go to the tab “Authorities” in the window “Certificate Manager” and click “Import…” Opera') }}.</p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/opera/` + lang + `/installing_in_opera3.png`)">
        <div style="height:0.75rem;"></div>
        <p v-html="$t('Find the file of CA Root Certificate - CA_RSA1.cer')"></p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/opera/` + lang + `/installing_in_opera4.png`)">
        <div style="height:0.75rem;"></div>
        <p>{{ $t('Click “Install certificate”') }}.</p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/opera/` + lang + `/installing_in_opera5.png`)">
        <div style="height:0.75rem;"></div>
        <p>{{ $t('Click “OK” in the system message') }}.</p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/opera/` + lang + `/installing_in_opera6.png`)">
        <div style="height:0.75rem;"></div>
        <p v-html="$t('Go to the tab “Authorities” in the window “Certificate Manager” and find the Root Certificate')"></p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/opera/` + lang + `/installing_in_opera7.png`)">
        <div style="height:0.75rem;"></div>
        <p>{{ $t('Select the certificate and click “View”') }}.</p>
        <p>{{ $t('Make sure that the certificate is valid') }}.</p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/opera/` + lang + `/installing_in_opera8.png`)">
        <div style="height:0.75rem;"></div>
        <p>{{ $t('lose the certificate viewing window. Go to the tab “Intermediate” in the window “Certificates Manager” and click “Import…”') }}.</p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/opera/` + lang + `/installing_in_opera9.png`)">
        <div style="height:0.75rem;"></div>
        <p v-html="$t('Find the file of CA Root Certificate - CA_RSA2.cer')"></p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/opera/` + lang + `/installing_in_opera10.png`)">
        <div style="height:0.75rem;"></div>
        <p>{{ $t('Click “Install certificate”') }}.</p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/opera/` + lang + `/installing_in_opera11.png`)">
        <div style="height:0.75rem;"></div>
        <p>{{ $t('Click “OK” in the system message') }}.</p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/opera/` + lang + `/installing_in_opera12.png`)">
        <div style="height:0.75rem;"></div>
        <p v-html="$t('Go to the tab “Intermediate” in the window “Certificate Manager” and find the Root Certificate')"></p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/opera/` + lang + `/installing_in_opera13.png`)">
        <div style="height:0.75rem;"></div>
        <p>{{ $t('Select the certificate and click “View”') }}.</p>
        <p>{{ $t('Make sure that the certificate is valid') }}.</p>
        <div style="height:0.75rem;"></div>
        <img :src="require(`@/assets/img/installing/opera/` + lang + `/installing_in_opera14.png`)">
        <div style="height:0.75rem;"></div>
        <p>{{ $t('Close the certificate viewing window') }}.</p>
        <p>{{ $t('Check for installation of CA Root Certificates is completed') }}.</p>
      </div>
    </transition>

  </div>

</template>

<script>
export default {
  name: 'IIB',
  data () {
    return {
      tab: 'explorer'
    }
  },
  computed: {
    lang () {
      return this.$i18n.locale
    }
  }
}
</script>

<style scoped lang="scss">
  .slide-fade-enter-active {
    transition: all .3s ease;
  }
  .slide-fade-leave-active {
    transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to {
    transform: translateX(10px);
    opacity: 0;
  }
</style>
