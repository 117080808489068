<template>

  <div class="column is-6">
    <h1 class="title is-3">{{ $t('Instructions for unlocking devices JaCarta PKI/GOST') }}</h1>
    <p><span v-html="$t('Download the utility')"></span>.</p>
    <p v-html="$t('Run the utility')"></p>
    <div style="height:0.75rem;"></div>
    <img v-bind:src="img1">
    <div style="height:0.75rem;"></div>
    <p v-html="$t('Select the tab PIN Management')">:</p>
    <div style="height:0.75rem;"></div>
    <img v-bind:src="img2">
    <div style="height:0.75rem;"></div>
    <p v-html="$t('In the field selection of operations, select Reset user PIN error counter')"></p>
    <div style="height:0.75rem;"></div>
    <img v-bind:src="img3">
    <div style="height:0.75rem;"></div>
    <p>{{ $t('In the field PIN code, enter the Administrator PIN: 1234567890') }}.</p>
    <div style="height:0.75rem;"></div>
    <img v-bind:src="img4">
    <div style="height:0.75rem;"></div>
    <p>{{ $t('Click the OK button') }}.</p>
    <div style="height:0.75rem;"></div>
    <img v-bind:src="img5">
    <div style="height:0.75rem;"></div>
    <p>{{ $t('The device is unlocking complete') }}.</p>
    <p v-html="$t('Note')"></p>
  </div>

</template>

<script>
export default {
  name: 'SupportJaCarta',
  computed: {
    lang () {
      return this.$i18n.locale
    },
    img1 () {
      const en = require('@/assets/img/jacarta/unlock_JaCarta1_en.png')
      const ru = require('@/assets/img/jacarta/unlock_JaCarta1.png')
      return this.lang === 'ru' ? ru : en
    },
    img2 () {
      const en = require('@/assets/img/jacarta/unlock_JaCarta2_en.png')
      const ru = require('@/assets/img/jacarta/unlock_JaCarta2.png')
      return this.lang === 'ru' ? ru : en
    },
    img3 () {
      const en = require('@/assets/img/jacarta/unlock_JaCarta3_en.png')
      const ru = require('@/assets/img/jacarta/unlock_JaCarta1.png')
      return this.lang === 'ru' ? ru : en
    },
    img4 () {
      const en = require('@/assets/img/jacarta/unlock_JaCarta4_en.png')
      const ru = require('@/assets/img/jacarta/unlock_JaCarta4.png')
      return this.lang === 'ru' ? ru : en
    },
    img5 () {
      const en = require('@/assets/img/jacarta/unlock_JaCarta5_en.png')
      const ru = require('@/assets/img/jacarta/unlock_JaCarta5.png')
      return this.lang === 'ru' ? ru : en
    }
  }
}
</script>
