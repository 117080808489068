<template>

  <div class="column is-6">
    <h1 class="title is-3">{{ $t('Start Smart Card Service') }}</h1>
    <p>1. {{ $t('Open the services control window') }}.</p>
    <p>2. {{ $t('Select Smart Card service from the list and click the active link “Start the service”') }}.</p>
    <div style="height:1rem;"></div>
    <img v-bind:src="img1">
  </div>

</template>

<script>
export default {
  name: 'SupportJaCarta',
  computed: {
    lang () {
      return this.$i18n.locale
    },
    img1 () {
      const en = require('@/assets/img/smartcard/smartcard1_en.jpg')
      const ru = require('@/assets/img/smartcard/smartcard1.jpg')
      return this.lang === 'ru' ? ru : en
    }
  }
}
</script>
